import React from 'react'

export const Panel = ({children, header, footer}) => {
  return (
    <div className=''>
        {header && <div className='font-Barlow font-semibold text-md pt-4 pb-3'>{header}</div>}
        <div className=''>{children}</div>
        {footer && <div className=''>{footer}</div>}
    </div>
  )
}
