import React, { useState } from "react";
import { useToggleRefinement } from "react-instantsearch-hooks-web";
import { Switch } from "@mantine/core";

export default function SwitchRefinement(props) {
  const [toggleValue, setToggleValue] = useState(false);

  const changeCallback = (event) => {
    setToggleValue(!toggleValue);
    refine({ isRefined: !event.target.checked });
  };

  const { refine } = useToggleRefinement(props);

  return (
    <Switch
      classNames={{ root: "mb-2", label: "text-[16px] text-center" }}
      styles={{ input: { backgroundColor: "yellow" } }}
      checked={toggleValue}
      label={props.label}
      color="bb-light-green"
      size="md"
      onChange={(event) => changeCallback(event)}
    />
  );
}
