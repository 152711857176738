import React from "react";
import { useForm } from "@mantine/form";
import { Button, Textarea } from "@mantine/core";
import { updateDoc, doc } from "firebase/firestore";
import { db } from "../../Firebase";
import { showNotification, updateNotification } from "@mantine/notifications";
import { AiOutlineCheck } from "react-icons/ai";
import { HiOutlineX } from "react-icons/hi";

const EditBidEntry = (props) => {

  const bidForm = useForm({
    initialValues: {
      objectID: props.objectID,
      title: props.title,
      url: props.url,
      state: props.state,
      agency: props.agency,
      open_date: props.open_date,
      close_date: props.close_date,
      description: props.description,
    },

    validate: {
      open_date: (value) => (/^\d{1,2}\/\d{1,2}\/\d{2,4}/.test(value) ? null : "Invalid date format"),
      close_date: (value) => (/^\d{1,2}\/\d{1,2}\/\d{2,4}/.test(value) ? null : "Invalid date format")
    }
  });

  return (
    <div className="m-x-xs m-3">
      <form
        onSubmit={bidForm.onSubmit(async (data, values) => {
          try {
            // Uploading Logic
            if (
              bidForm.isDirty()
            ) {
              showNotification({
                id: "update",
                title: "Updating bid ",
                color: "blue",
                loading: true,
              });

              let newClose = new Date(data.close_date);
              let newOpen = new Date(data.open_date);

              const docRef = doc(db, "bids", props.objectID);

              try {
                const document = await updateDoc(docRef, {
                  agency: data.agency,
                  close_date: data.close_date,
                  close_timestamp: Math.floor(newClose.getTime() / 1000),
                  description: data.description,
                  login_required: props.login_required,
                  open_date: data.open_date,
                  open_timestamp: Math.floor(newOpen.getTime() / 1000),
                  payment_required: props.payment_required,
                  site_name: props.site_name,
                  state: data.state,
                  title: data.title,
                  url: props.url
                });

                updateNotification({
                  id: "update",
                  title: "Update successful! Please wait a few seconds for the database to reindex the changes.",
                  color: "green",
                  icon: <AiOutlineCheck size={16} />,
                  autoClose: 2000,
                });

              } catch (e) {
                console.log(e);
                updateNotification({
                  id: "update",
                  title: "Update failed.",
                  color: "red",
                  icon: <HiOutlineX size={16}/>,
                  autoClose: 2000,
                });
              }

            } else {
              showNotification({
                id: "no-change",
                title: "Please edit a field in order to update a bid.",
                icon: <HiOutlineX size={16}/>,
                color: "red",
              });
            }
          } catch (e) {
            console.log(e);
          }
        })}
      >
        <section className="m-2 p-2 ">
          <article className="px-2">
            <h1 className="font-medium">Edit this bid</h1>
            <h2 className="text-justify">
              Make edits to the fields, then hit the "Save" button to save your changes.
            </h2>
          </article>
        </section>
        <section className="m-2 p-2 ">
          <article className="px-2">
            <Textarea
              mt="sm"
              mb="md"
              label="Firebase Object ID"
              autosize
              disabled
              placeholder="objectID"
              {...bidForm.getInputProps("objectID")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Title"
              autosize
              disabled
              placeholder="RFP Name"
              {...bidForm.getInputProps("title")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="URL"
              autosize
              disabled
              placeholder="URL"
              {...bidForm.getInputProps("url")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Agency"
              autosize
              placeholder="Agency"
              {...bidForm.getInputProps("agency")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="State (must be abbreviated)"
              autosize
              placeholder="State"
              {...bidForm.getInputProps("state")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Open Date"
              autosize
              placeholder="Open Date"
              {...bidForm.getInputProps("open_date")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Close Date"
              autosize
              placeholder="Close Date"
              {...bidForm.getInputProps("close_date")}
            />
            <Textarea
              mt="sm"
              mb="md"
              label="Description"
              autosize
              placeholder="Description"
              {...bidForm.getInputProps("description")}
            />
          </article>
        </section>
        <section className="m-2 p-1 flex justify-start mt-2">
          <Button
            variant="outline"
            size="sm"
            radius="xl"
            type="submit"
            className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
          >
            Save Changes
          </Button>
        </section>
      </form>
    </div>
  );
};

export default EditBidEntry;
