import { useInstantSearch } from 'react-instantsearch-hooks-web'
import { Loader } from '@mantine/core';

// Loader for stalled searches
export const SearchLoading = ({ children }) => {

    const { status } = useInstantSearch();

    if (status === "stalled") {
        return <Loader variant="dots" color='bb-light-green' size='xl' className='flex m-auto items-center justify-center my-40'/>;
    }

    return children;
}

