import React from "react";

const StatusLogs = ({ log_array }) => {
  if (log_array.length === 0) {
    return <p> No logs available </p>;
  }

  return (
    <div className="max-h-[540px] overflow-auto">
      {log_array.map((line) => {
        let stream = line.stream;
        let classNames = "flex ml-2 w-3/4";
        if (stream === "failure") {
          classNames = "flex ml-2 text-red-600 font-bold w-3/4";
        } else if (stream === "stderr") {
          classNames = "flex ml-2 text-orange-600 font w-3/4";
        } else if (stream === "overview") {
          classNames = "flex ml-2 text-blue-600 font-bold w-3/4";
        } else {
          classNames = "flex ml-2 w-3/4";
        }
        return (
          <div className="flex">
            [<p className="flex min-w-fit text-green-600">{new Date(line.time_ns1970 / 1000000).toTimeString().split(" ")[0]}</p>]
            [<p className="flex min-w-fit text-purple-600">{line.stream}</p>]
            <p className={classNames}>{line.data}</p>
          </div>
        );
      })}
    </div>
  );
};

export default StatusLogs;
