import React from 'react'
import { Image } from '@mantine/core'
import BananaBunch from '../data/Bid Banana BUNCH Banana Full Color.png'
import BidBananaWoman from '../data/BidBananaWoman.png'

export const EmptyQuery = () => {

  return (
    <div className='flex-col sm:pl-16 pb-20 m-auto justify-center items-center'>
      <div className='items-center rounded-lg'>
        <Image
          src={BidBananaWoman}
          alt="No way!"
          height={350}
          fit='scale-down'
          className='my-1 p-2 '
        />
        <p className='text-2xl font-semibold mb-0 text-center text-slate-800'>
          Enter a search query of at least 3 characters to view search results!
        </p>
      </div>
    </div>
  )
}
