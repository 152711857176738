import { Button, Card, Image } from "@mantine/core";
import React, { useState } from "react";
import checkout from "../stripe/functions/Checkout";
import GlobalLoader from "./GlobalLoader";
import Peel from "../data/BananaPeel.png";
import { useUserAuth } from "../contexts/UserAuthContext";
import { Link } from "react-router-dom";

const Paywall = ({ text, body, buttonText }) => {
  const { user } = useUserAuth();
  const [loading, setLoading] = useState(false);

  if (loading) {
    return <GlobalLoader />;
  }

  return (
    <div
      id="PageContent"
      className="h-screen relative z-10 max-w-7xl w-full margin-auto"
    >
      <div id="PageBodyContents">
        <div className="flex-col align items-center mb-4 overflow-hidden py-20 px-5">
          <Card.Section>
            <Image
              src={Peel}
              alt="No way!"
              height={120}
              fit="scale-down"
              className="m-4 p-2"
            />
          </Card.Section>
          <span>
            {/* svg can go here */}
            <h3 className=" text-xl mx-6 font-semibold text-gray-800 mb-2 text-center">
              {text}
            </h3>
            <p className="mb-6 text-center">{body}</p>
            <div className="flex justify-center m-2">
              <Link to="/payments">
                <Button
                  variant="default"
                  size="md"
                  radius="xl"
                  className="flex w-fit hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 hover:bg-bb-light-green border-black duration-900 text-gray-600 font-normal"
                >
                  {buttonText}
                </Button>
              </Link>
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Paywall;
