import React, { useEffect } from "react";
import {
  Paper,
  createStyles,
  TextInput,
  Loader,
  Button,
  Title,
  Text,
  Divider,
  Image,
} from "@mantine/core";
import background from "../data/collage.png";
import { CTAButton } from "../components/index.js";
import { Link } from "react-router-dom";
import { useForm } from "@mantine/form";
import { useUserAuth } from "../contexts/UserAuthContext.js";
import { useNavigate } from "react-router-dom";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { IoLogOut } from "react-icons/io5";
import { setUserId } from "firebase/analytics";
import { useAbleCDPTracking } from "../hooks/useAbleTracking";

const useStyles = createStyles((theme) => ({

  wrapper: {
    minHeight: 1000,
    backgroundSize: "100%",
    backgroundImage: `url(${background})`,
  },

  form: {
    borderRight: `1px solid ${theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
      }`,
    minHeight: 1000,
    maxWidth: 690,
    paddingTop: 80,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },

    // padding set to 15 on mobile devices
    [theme.fn.smallerThan("sm")]: {
      padding: 15,
    },
  },

  // if We want to put a logo
  // logo: {
  //   width: 120,
  //   display: 'block',
  //   marginLeft: 'auto',
  //   marginRight: 'auto',
  // },
}));

const FinalQuestions = () => {
  useAbleCDPTracking()

  const { classes } = useStyles();

  const { user, setData } = useUserAuth();

  // Validation logic
  const form = useForm({
    initialValues: { organizationName: "", phoneNumber: "" },

    validate: {
      organizationName: (value) =>
        value.length < 2
          ? "Organization Name must be a least 2 characters"
          : null,
      phoneNumber: (value) =>
        value.length < 9 ? "Must be a valid phone number" : null,
    },
  });

  let navigate = useNavigate();

  if (user == null) {
    return (
      <div
        id="wrapper"
        className="h-fit flex-grow flex-col w-full relative justify-center"
      >
        <Loader
          variant="dots"
          color="bb-light-green"
          size="xl"
          className="flex m-auto items-center justify-center h-screen"
        />
      </div>
    )
  }

  return (
    <div
      id="wrapper"
      className="h-fit m-auto flex-grow flex-col w-full relative justify-center "
    >
      <div className={classes.wrapper}>
        {/* Left side / form side  */}
        <div
          id="wrapper"
          className="h-fit flex-grow w-full relative justify-center"
        >
          {/* submit values to user doc */}
          <form
            onSubmit={form.onSubmit(async (values) => {
              try {
                if (
                  values.organizationName != "" &&
                  values.phoneNumber != ""
                ) {
                  const userDocRef = doc(db, "users", user.uid);
                  await updateDoc(userDocRef, {
                    organization_name: values.organizationName,
                    phone_number: values.phoneNumber,
                  });
                  const userDocSnap = await getDoc(userDocRef);
                  let data = userDocSnap.data();
                  setData(data);
                  navigate('/search')
                } else {
                  alert("field cannot be left empty");
                }
              } catch (e) {
                console.log(e);
              }
            })}
          >
            <Paper className={classes.form} radius={0} p={100}>
              <Title
                order={1}
                className="font-Barlow font-base"
                align="center"
                mt="md"
                mb={40}
              >
                Thank you for subscribing!
              </Title>

              <Title
                order={3}
                className="font-Barlow font-base"
                align="center"
                mt="md"
                mb={40}
              >
                Just a few more questions...
              </Title>

              <TextInput
                my="md"
                label="Organization Name"
                required
                placeholder="Bid Banana"
                size="md"
                {...form.getInputProps("organizationName")}
              />
              <TextInput
                label="Phone Number"
                required
                placeholder="555-555-5555"
                size="md"
                {...form.getInputProps("phoneNumber")}
              />

              <div className=" justify-end p-2 flex  sm:p-4 sm:flex sm:justify-end">
                <Button
                  // onClick={() => {navigate('/')}}
                  variant="outline"
                  size="sm"
                  type="submit"
                  radius="xl"
                  className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 hover:bg-bb-light-green bg-bb-light-green border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
                >
                  Submit
                </Button>
              </div>
            </Paper>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FinalQuestions;
