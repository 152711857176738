import {
  ActionIcon,
  Button,
  Drawer,
  TextInput,
  Text,
  Card,
} from "@mantine/core";
import { useForm, isEmail } from "@mantine/form";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { BiBell } from "react-icons/bi";
import { CurrentRefinements } from "react-instantsearch-hooks-web";
import { useUserAuth } from "../contexts/UserAuthContext";
import algoliaHelper from "algoliasearch-helper";
import algoliasearch from "algoliasearch/lite";
import { setDoc, doc, getDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { Link } from "react-router-dom";
import { useInstantSearch } from "react-instantsearch-hooks-web";
import SavedSearchesCardMinimal from "./SavedSearchesCardMinimal";
import { BsArrowRightShort } from "react-icons/bs";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import StateJSON from '../data/states.json';

const SearchAlerts = ({ email }) => {
  const [opened, setOpen] = useState(false);
  const { results } = useInstantSearch();

  // State to hold saves search data
  const [savedSearches, setSavedSearches] = useState([]);
  const alertButtonMobile = useMediaQuery("(min-width: 900px)");

  const { user } = useUserAuth();

  const form = useForm({
    initialValues: {
      name: "",
      email: user ? user.email : " ",
      query: results.query,
    },

    validate: {
      name: (value) =>
        value.length < 3 ? "Name must be at least 3 characters long" : null,
      email: isEmail("Invalid email"),
      query: (value) => value.length < 3 ? "Query must be at least 3 characters long" : null,
    },
  });

  // Retrieve the saved searches data from firebase based on the users uid
  useEffect(() => {
    const fetchSavedSearches = async () => {
      if (user) {
        try {
          // Retrieve saved searches
          const userSavedSearchRef = doc(db, "saved_searches", user.uid);
          const docSnapshot = await getDoc(userSavedSearchRef);

          if (docSnapshot.exists()) {
            const searchData = docSnapshot.data();
            const searches = searchData.savedSearches || [];
            setSavedSearches(searches);
            // console.log(searches);
          } else {
            setSavedSearches([]);
          }
        } catch (error) {
          console.error("Error fetching saved searches", error);
        }
      }
    };

    fetchSavedSearches();
  }, [user]);

  useEffect(() => {
    form.setValues({query: results.query})
  }, [opened]);

  const searchClient = algoliasearch("U3KGE4YPBJ", user.searchKey);
  // Transform the current search state (query and facets) into a string
  // This function is just checking if the necessary input is present to perform the conversion, and returning null if it's not.
  const serializeSearchState = (results) => {
    const state = results && results._state;
    // console.log(results._state);
    if (!state) {
      return null;
    }

    // The algoliaHelper function takes three arguments: the search client (which is
    // the object responsible for making requests to the Algolia API), the name of the
    // search index, and the current search state.
    const helper = algoliaHelper(searchClient, state.index, state);
    // getQuery() returns the current query as an object.
    const rawQuery = helper.getQuery();
    // console.log("Current search query:", rawQuery);
    // console.log("return statement:" + JSON.stringify(rawQuery));
    return JSON.stringify(rawQuery);
  };

  // Save the serialized search state to the user's saved searches list in the Firebase database.
  // First, retrieve the user's document using their user id, then get the existing saved searches array
  // from the user document or initialize an empty array if no saved searches exist yet. Next, add the
  // new search results to the saved searches array and update the user's document with the updated
  // saved searches array.
  //
  const saveSearch = async (name) => {
    // console.log("Save search button clicked");
    const query = serializeSearchState(results);
    const location = window.location.href;
    const route = location.substring(window.location.href.indexOf("/results"));
    const searchDocRef = doc(db, "saved_searches", user.uid);
    const searchDocSnap = await getDoc(searchDocRef);

    //Check if the name already exists in the saved searches array
    const savedSearches = searchDocSnap.exists()
      ? searchDocSnap.data().savedSearches
      : null;
    // console.log(savedSearches);
    if (
      savedSearches &&
      savedSearches.some((search) => search.name === form.values.name)
    ) {
      // console.log("This name has already been taken");
      form.setFieldError("name", "This name has already been taken");
      return;
    }

    if (!searchDocSnap.exists()) {
      await setDoc(searchDocRef, {
        savedSearches: [
          { email: user.email, query, name: form.values.name, route: route },
        ],
      });
      // console.log(user.email);
    } else {
      // This line of code retrieves any existing saved searches for the user from their document.
      // If saved searches exists, it assigns them to a variable called "userSavedSearch".
      // If no saved searches exist, it initializes an empty array and assigns that to "userSavedSearch".
      const userSavedSearch = Array.isArray(searchDocSnap.data()?.savedSearches)
        ? searchDocSnap.data().savedSearches
        : []; // Retrieve the existing saved searches from the user document or initialize an empty array
      if (query) {
        const updatedSavedSearch = [
          ...userSavedSearch,
          { email: user.email, query, name: form.values.name, route: route },
        ]; // Add the new query to the saved searches array
        // const updatedSavedSearch = [...userSavedSearch, { email: user.email, query }]; If email is needed
        await setDoc(searchDocRef, { savedSearches: updatedSavedSearch }); // Save the updated saved searches array to the user document

        // console.log("Query successfully saved!");

        setSavedSearches(updatedSavedSearch);

        showNotification({
          title: "Search Saved",
          icon: <IconCheck size={18} />,
          autoClose: 3000,
          message:
            "Your search has been successfully saved!",
          color: "green",
        });
        setOpen(false);
      } else {
        console.error("Cannot save null query. Please refine your search");
      }
    }
  };

  const CustomDrawerTitle = () => {
    return <h2 className="text-center text-xl font-bold pl-3">Save Search</h2>;
  };

  return (
    <div>
      {/* Drawer / popup to input search terms, etc  */}

      <Drawer
        overlayColor="none"
        id="savedSearchDrawer"
        overlayOpacity={0}
        closeOnEscape
        opened={opened}
        onClose={() => setOpen(false)}
        title={<CustomDrawerTitle />}
        size="xl"
        padding="lg"
        position="left"
      >
        {/* Drawer content */}
        <div
          className="overflow-auto"
          style={{
            maxHeight: "90vh",
            paddingRight: "16px",
            scrollbarWidth: "none",
            scrollbarColor: "gray lightgray",
          }}
        >
          <div className="pl-3">
            {/* Instructions */}
            <div className="pb-4">
              <p className="text-gray-600 pb-2">
                Stay in the know with Bid Banana's saved search function. Get
                notified via email when new bids are posted that match your search
                criteria and stay ahead of the competition!
              </p>
              <p className="text-gray-600">
                To get started, simply fine tune your current search to your liking, 
                give your saved search a name, and click 'Save This Search!'. Alert emails will be sent
                daily at 12:00 PM EST and will contain any new results that are found matching your saved searches!
              </p>
              {/**** TODO INFO PAGE **** <a className="float-right underline text-blue-600 hover:text-blue-800 visited:text-purple-600" href="/savedsearch">
                Learn more...
              </a> */}
            </div>
            {/* <div className="my-2 flex ">
              <p>
                {results.nbHits} results found for the search term “
                {results.query}
                “.
                <br />
              </p>
            </div> */}

            {/* Refinements  */}
            <div className="mb-4">
              <div className="flex">
                <CurrentRefinements
                  classNames={{
                    root: "p-0  ",
                    list: "p-0 font-light flex",
                    item: "px-2 py-1 mr-1 bg-bb-light-blue rounded",
                    category: "px-1 mx-0 ",
                    categoryLabel: " px-1 py-1",
                    delete: " ml-1 text-sm font-semibold",
                    label: "pl-0 font-semibold capitalize",
                  }}
                />
              </div>
            </div>
            <div id="input wrapper">
              <form
                id="savedSearch-form"
                onSubmit={form.onSubmit(async (values) => {
                  try {
                    await saveSearch(values.name && values.email);
                  } catch (e) {
                    // console.log(e);
                  }
                })}
              >
                <section className="my-4">
                <TextInput
                    mt="xs"
                    label="Search Query"
                    disabled
                    required
                    {...form.getInputProps("query")}
                  />
                  <TextInput
                    mt="xs"
                    label="Save Search Name"
                    required
                    error={form.errors.name && "Invalid name"}
                    placeholder="e.g. Marketing in California"
                    {...form.getInputProps("name")}
                  />
                  <div className="hidden">
                    <TextInput
                      mt="md"
                      label="Email"
                      required
                      disabled
                      placeholder="Email"
                      {...form.getInputProps("email")}
                    />
                  </div>

                  {/* Save Search */}
                  <div className="flex justify-center mb-4 py-2 my-4 sm:my-4">
                    <Button
                      variant="outline"
                      size="md"
                      id="saveThisSearch"
                      type="submit"
                      radius="xl"
                      className="hover:shadow-[0_5px_0px_rgba(0,0,0)] mr-2 transition-all font-medium text-md ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black w-full hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black"
                    >
                      Save This Search!
                    </Button>
                  </div>

                  {/* existing saved searches  */}
                  <Text className="text-xl text-center font-semibold pt-2 pb-6">
                    View Your Existing Saved Searches
                  </Text>
                  {/* mapping over saved searches in doc */}
                  {savedSearches.length > 0 ? (
                    savedSearches.map((search, index) => (
                      <SavedSearchesCardMinimal
                        search={search}
                        index={index}
                        key={index}
                      />
                    ))
                  ) : (
                    <div className="flex w-full">
                      <Card
                        withBorder
                        radius="md"
                        className="sm:w-full my-2 py-12"
                      >
                        <div className="flex justify-center">
                          <Text className="flex justify-center ">
                            You do not have any saved searches.
                          </Text>
                        </div>
                        <div className="flex justify-center">
                          <div className="mt-2 mb-4 sm:w-4/5 flex justify-center">
                            <Text mr="md" fz="sm" c="dimmed" align="center">
                              Save a search to get notified about new results
                              matching your search criteria.
                            </Text>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}

                  <div className="mt-9 ">
                    <Text mr="md" fz="md" c="dimmed" align="center">
                      View and edit all of your saved searches in&nbsp;&nbsp;
                      <div className=" inline-block">
                        <Link
                          to="/savedsearches"
                          className="font-semibold text-bb-light-green"
                        >
                          Settings&nbsp;
                          <ActionIcon className=" hover:bg-white inline-block align-middle ">
                            <BsArrowRightShort
                              size={20}
                              variant="transparent"
                            />
                          </ActionIcon>
                        </Link>
                      </div>
                    </Text>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </Drawer>

      {/* Conditional Drawer button: Mobile is just bell icon all other screen sized use button with icon  */}
      {alertButtonMobile ? (
        <Button
          variant="default"
          id="saveSearchModalToggle"
          leftIcon={<BiBell color="black" size="1rem" />}
          size="md"
          radius="xl"
          onClick={() => setOpen(true)}
          className=" hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 border-black bg-green-300 transition-all ease-in-out duration-200 hover:bg-green-300 duration-900 font-normal"
        >
          Save Search
        </Button>
      ) : (
        <ActionIcon onClick={() => setOpen(true)}>
          <BiBell variant="default" size="1.4rem" color="gray" />
        </ActionIcon>
      )}
    </div>
  );
};

export default SearchAlerts;
