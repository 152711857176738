import React, { useState } from "react";
import { RefinementList } from "react-instantsearch-hooks-web";
import { Panel } from ".";
import { Button, Drawer, ScrollArea } from "@mantine/core";
import { Stats, SwitchRefinement } from ".";
import { useInstantSearch } from "react-instantsearch-hooks-web";

const MobileFilter = (props) => {
  // drawer for mobile filter
  const [openDrawer, setOpenDrawer] = useState(false);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const Callback = async () => {
    let currentUiState = indexUiState;
    setOpenDrawer(false);
    // Replace this logic later
    sleep(600).then(() => {
      setIndexUiState(currentUiState);
    });
  };

  const { indexUiState, setIndexUiState } = useInstantSearch();

  return (
    <>
      {/*Drawer for mobile filters  */}
      <Drawer position="bottom" transition="slide-up" transitionDuration={400} overlayOpacity={0} overlayBlur={0} size="90%" opened={openDrawer} onClose={Callback} className="rounded-t-xl z-1">
        {/* Drawer content  */}
        <section className="overflow-auto z-1 ">
          <div id="containerHeader" className="border-b-1 border-bb-dark-blue flex items-center justify-between p-8 ">
            <h2 className="font-semibold text-xl">Filters</h2>
            <div id="Stats" className="flex items-center overflow-auto">
              <Stats user={props.user} />
            </div>
          </div>
          <ScrollArea style={{ height: "70vh" }} className="flex items-center mt-1">
            <div id="containerBody" className="pl-8 pr-5 pt-2 pb-20 max-w-64 max-h-full ">
              <Panel header="STATE">
                <RefinementList
                  attribute="state"
                  sortBy={["isRefined", "name:asc"]}
                  limit={5}
                  showMore={true}
                  showMoreLimit={100}
                  transformItems={props.transformStateRefinementListItems}
                  classNames={{
                    root: "bg-white p-4 rounded-md border-1 font-Barlow",
                    list: "p-0 font-Barlow",
                    item: "py-2 border-gray-200",
                    noResults: "p-4 font-Barlow",
                    labelText: "pl-2 font-Barlow text-right",
                    label: "p-2 text-gray-900 text-right",
                    count: "p-2 text-gray-500",
                    selectedItem: "text-blue-500 font-semibold",
                    showMore: "font-Barlow text-[#1c7ed6] pt-3 pl-2 hover:underline",
                    checkbox: "accent-bb-light-green hover:accent-bb-light-green",
                  }}
                />
              </Panel>

              {props.user.admin && (
                <Panel header="SITE NAME">
                  <RefinementList
                    attribute="site_name"
                    searchable={true}
                    sortBy={["isRefined", "count:desc", "name:asc"]}
                    searchablePlaceholder="&nbsp;&nbsp;Search site name"
                    limit={5}
                    showMoreLimit={2000}
                    showMore={true}
                    classNames={{
                      searchBox: "w-fit border-1 text-sm font-Barlow",
                      root: "bg-white p-4 rounded-md border-1 font-Barlow",
                      list: "p-0 font-Barlow",
                      item: "py-2 border-gray-200",
                      noResults: "p-4 font-Barlow",
                      labelText: "pl-2 font-Barlow text-right",
                      label: "p-2 text-gray-900 text-right",
                      count: "p-2 text-gray-500",
                      selectedItem: "text-blue-500 font-semibold",
                      showMore: "font-Barlow text-[#1c7ed6] pt-3 pl-2 hover:underline",
                      checkbox: "accent-bb-light-green hover:accent-bb-light-green",
                    }}
                  />
                </Panel>
              )}

              {props.user.admin && <Panel header="AGENCY">
                <RefinementList
                  attribute="agency"
                  searchable={true}
                  sortBy={["isRefined", "name:asc"]}
                  limit={5}
                  searchablePlaceholder="&nbsp;&nbsp;Search agencies"
                  showMoreLimit={50}
                  showMore={true}
                  classNames={{
                    searchBox: "w-fit border-1 text-sm font-Barlow",
                    root: "bg-white p-4 rounded-md border-1 font-Barlow",
                    list: "p-0 font-Barlow",
                    item: "py-2 border-gray-200",
                    noResults: "p-4 font-Barlow",
                    labelText: "pl-2 font-Barlow text-right",
                    label: "p-2 text-gray-900 text-right",
                    count: "p-2 text-gray-500",
                    selectedItem: "text-blue-500 font-semibold",
                    showMore: "font-Barlow text-[#1c7ed6] pt-3 pl-2 hover:underline",
                    checkbox: "accent-bb-light-green hover:accent-bb-light-green",
                  }}
                />
              </Panel>}

              <Panel header="Additional Steps">
                <SwitchRefinement attribute="login_required" label="Hide Login Required" on={false} />
              </Panel>
              <br />
            </div>
          </ScrollArea>
        </section>
        <footer className="border-t bg-white border-bb-dark-blue bottom-0 flex justify-center fixed py-4 z-5 w-full">
          <div id="filtersButtonWrapper" className="px-3">
            <Button
              onClick={() => Callback()}
              variant="outline"
              size="md"
              radius="xl"
              className="hover:shadow-[0_5px_0px_rgba(0,0,0)]  transition-all font-medium text-sm ease-in-out duration-200 text-gray-900 bg-green-300 border-1 border-black hover:bg-green-300 hover:-translate-y-1 hover:drop-shadow-lg shadow-black "
            >
              See Results
            </Button>
          </div>
        </footer>
      </Drawer>

      {/* Mobile Design elements */}
      <div id="mobileFilterButton" className="">
        <span className="flex bg-cyan-300">
          <aside data-layout="mobile" className="md:hidden">
            <Button
              data-action="open-overlay"
              variant="default"
              size="sm"
              radius="xl"
              //
              className="items-center bottom-5 flex justify-center left-1/2 -translate-x-1/2 fixed | hover:shadow-[0_5px_0px_rgba(0,0,0)] hover:-translate-y-1 border-1 transition-all ease-in-out duration-200 bg-white hover:bg-white border:black duration-900 text-gray-600 font-normal"
              onClick={() => setOpenDrawer(true)}
            >
              Filters
            </Button>
          </aside>
        </span>
      </div>
    </>
  );
};

export default MobileFilter;
